import { useEffect, useRef } from 'react';
import Hls, { ErrorDetails, ErrorTypes, Events } from 'hls.js';
import _ from 'lodash';
import { Box } from '@mui/material';

interface HlsVideoPlayerProps {
  src: string;
  videoRef: React.MutableRefObject<HTMLVideoElement>;
  // optional overrides
  autoPlay?: boolean;
  controls?: boolean;
  onLoadedMetadata?: () => void;
  height?: string;
  width?: string;
  customStyles?: React.CSSProperties;
}

const HlsVideoPlayer = ({
  src,
  autoPlay = false,
  controls = true,
  onLoadedMetadata = undefined,
  height = '100%',
  width = '100%',
  customStyles = {},
  videoRef,
}: HlsVideoPlayerProps) => {
  useEffect(() => {
    if (Hls.isSupported()) {
      const hls = new Hls({
        fragLoadingMaxRetry: 3,
        fragLoadingRetryDelay: 2000,
        manifestLoadingMaxRetry: 3,
        manifestLoadingRetryDelay: 2000,
        appendErrorMaxRetry: 3,
        nudgeMaxRetry: 3,
      });
      hls.loadSource(src);
      hls.attachMedia(videoRef.current);

      const handleLoadedMetadata = () => !_.isUndefined(onLoadedMetadata) && onLoadedMetadata();

      hls.on(Events.ERROR, (event, data) => {
        if (data.fatal) {
          switch (data.type) {
            case ErrorTypes.NETWORK_ERROR:
              hls.startLoad();
              break;
            case ErrorTypes.MEDIA_ERROR:
              // added only few fragment/buffer errors that cannot be recovered
              const FragmentErrors = [
                ErrorDetails.FRAG_PARSING_ERROR,
                ErrorDetails.FRAG_DECRYPT_ERROR,
                ErrorDetails.FRAG_LOAD_ERROR,
              ];
              const BufferErrors = [
                ErrorDetails.BUFFER_APPENDING_ERROR,
                ErrorDetails.BUFFER_APPEND_ERROR,
                ErrorDetails.BUFFER_INCOMPATIBLE_CODECS_ERROR,
                ErrorDetails.BUFFER_ADD_CODEC_ERROR,
              ];
              if (FragmentErrors.includes(data.details)) {
                console.error('HLS Fragment Error Occurred - stop loading', src, event, data);
                hls.stopLoad();
              } else if (BufferErrors.includes(data.details)) {
                console.error('HLS Buffer Error Occurred - stop loading', src, event, data);
                hls.stopLoad();
              } else {
                hls.recoverMediaError();
              }
              break;
            default:
              console.error(
                'Cannot Recover HLS Fatal Error - destroying instance',
                src,
                event,
                data
              );
              hls.destroy();
          }
        } else {
          hls.startLoad();
        }
      });

      videoRef.current.addEventListener('loadedmetadata', handleLoadedMetadata);
      return () => {
        videoRef.current &&
          videoRef.current.removeEventListener('loadedmetadata', handleLoadedMetadata);
        hls && hls.destroy(); // this is to destroy the hls context and free up resources on unmount
      };
    }
  }, []);

  return (
    <Box height={height} width={width}>
      <video
        ref={videoRef}
        controls={controls}
        autoPlay={autoPlay}
        {...(customStyles ? { style: customStyles } : {})}
        controlsList="fullscreen play timeline volume remoteplayback playbackrate"
        width="100%"
        height="100%"
      />
    </Box>
  );
};

export default HlsVideoPlayer;
