import { get } from 'aws-amplify/api';

import environment from 'config';
import { GroupInfo } from 'types';

export const getAllGroups = async (): Promise<GroupInfo[]> => {
  const { body } = await get({
    apiName: environment.API.REST.NAME,
    path: 'group',
    options: {},
  }).response;
  return (await body.json()) as unknown as GroupInfo[];
};

export const getGroupById = async (id: string): Promise<GroupInfo> => {
  const { body } = await get({
    apiName: environment.API.REST.NAME,
    path: `group/${id}`,
    options: {},
  }).response;
  return (await body.json()) as unknown as GroupInfo;
};
