import { get } from 'aws-amplify/api';

import environment from 'config';

import { Activity } from '../types';

export class EventActivityService {
  static async getActivityLogs(queryParams: {
    date?: string | number;
    eventId?: string;
  }): Promise<Activity[]> {
    const { body } = await get({
      apiName: environment.API.REST.NAME,
      path: 'event/activity',
      options: {
        queryParams: queryParams as Record<string, string>,
      },
    }).response;
    return (await body.json()) as Activity[];
    // TODO: use for mock api call
    // console.log('getActivityLogs ', queryParams.date, queryParams.eventId);
    // return new Promise((resolve) => {
    //   setTimeout(() => resolve(dummyActivityLogs), 6000);
    // });
  }
}
