import { del, get, patch, post, put } from 'aws-amplify/api';

import { EventStreamStatus } from 'constants/StreamConstants';

import environment from 'config';
import {
  AnalyticsResponse,
  APIEvent,
  CreateEventPayload,
  DeleteEventPayload,
  EventRequestQueryParams,
  SingleAPIEvent,
  UpdateEventPayload,
} from 'types';

export const listEvents = async (queryParams: EventRequestQueryParams): Promise<APIEvent[]> => {
  const { body } = await get({
    apiName: environment.API.REST.NAME,
    path: 'event/',
    options: {
      queryParams: queryParams as unknown as Record<string, string>,
    },
  }).response;
  return (await body.json()) as unknown as APIEvent[];
};

export const getAnalyticsEvents = async (
  queryParams: EventRequestQueryParams
): Promise<AnalyticsResponse[]> => {
  const { body } = await get({
    apiName: environment.API.REST.NAME,
    path: 'event/analytics',
    options: { queryParams: queryParams as unknown as Record<string, string> },
  }).response;
  return (await body.json()) as unknown as AnalyticsResponse[];
};

export const getEvent = async (id: string): Promise<SingleAPIEvent> => {
  const { body } = await get({
    apiName: environment.API.REST.NAME,
    path: `event/${id}`,
    options: {},
  }).response;
  return (await body.json()) as unknown as SingleAPIEvent;
};

export const createEvent = async (createEventPayload: CreateEventPayload): Promise<void> => {
  await post({
    apiName: environment.API.REST.NAME,
    path: 'event/',
    options: { body: createEventPayload },
  }).response;
};

export const updateEvent = async (updateEventPayload: UpdateEventPayload): Promise<void> => {
  await put({
    apiName: environment.API.REST.NAME,
    path: `event/${updateEventPayload.id}`,
    options: { body: updateEventPayload },
  }).response;
};

export const deleteEvent = async ({ eventId }: DeleteEventPayload): Promise<void> => {
  await del({
    apiName: environment.API.REST.NAME,
    path: `event/${eventId}`,
    options: {},
  }).response;
};

export const runningEvents = async (): Promise<APIEvent[]> => {
  const { body } = await get({
    apiName: environment.API.REST.NAME,
    path: 'event/running',
    options: {},
  }).response;
  return (await body.json()) as unknown as APIEvent[];
};

// export const createEventStream = async (eventId: string): Promise<void> =>
//   API.post(environment.API.REST.NAME, `event/${eventId}/stream/create`, {
//     body: { status: EventStreamStatus.CREATED },
//   });

// export const startEventStream = async (eventId: string): Promise<void> =>
//   API.put(environment.API.REST.NAME, `event/${eventId}/stream/start`, {
//     body: { status: EventStreamStatus.STARTED },
//   });

// export const stopEventStream = async (eventId: string): Promise<void> =>
//   API.put(environment.API.REST.NAME, `event/${eventId}/stream/stop`, {
//     body: { status: EventStreamStatus.STOPPED },
//   });

// export const destroyEventStream = async (eventId: string): Promise<void> =>
//   API.del(environment.API.REST.NAME, `event/${eventId}/stream/destroy`, {
//     body: { status: EventStreamStatus.DESTROYED },
//   });

export const eventStreamOperation = async ({
  eventId,
  operation,
}: {
  eventId: string;
  operation: string;
}): Promise<void> => {
  await post({
    apiName: environment.API.REST.NAME,
    path: 'event/operate',
    options: { body: { eventId, eventOperation: operation } },
  }).response;
};

export const switchEventStreamSource = async ({
  eventId,
  sourceId,
}: {
  eventId: string;
  sourceId: string;
}): Promise<void> => {
  await post({
    apiName: environment.API.REST.NAME,
    path: 'event/operate/source-select',
    options: {
      body: {
        eventId,
        eventOperation: EventStreamStatus.SOURCE_SELECTED,
        sourceId,
      },
    },
  }).response;
};

// Brightcove
export const publishBrightcove = async (
  id: string,
  hlsUrl: string,
  videoId: string
): Promise<void> => {
  await patch({
    apiName: environment.API.REST.NAME,
    path: `event/${id}/publish`,
    options: {
      body: {
        remoteUrl: hlsUrl,
        videoId,
      },
    },
  }).response;
};

// export const resetEventStream = async (eventId: string): Promise<void> =>
//   API.put(environment.API.REST.NAME, `event/${eventId}/stream/reset`, {
//     body: { status: EventStreamStatus.NON_EXISTENT },
//   });
