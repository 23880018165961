import React, { useEffect, useState } from 'react';
import { DateRange } from 'react-date-range';
import { enUS } from 'date-fns/locale';
import moment from 'moment';
import theme from 'themes/baseTheme';

import {
  Box,
  Chip,
  IconButton,
  InputAdornment,
  Popover,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DateRangeIcon } from '@mui/x-date-pickers';

import 'react-date-range/dist/theme/default.css';
import 'react-date-range/dist/styles.css';

interface DateRangePickerProps {
  dateObj: {
    fromDate: Date;
    toDate: Date;
    count: number;
    anchorEl: HTMLButtonElement | null;
    setFromDate: (date: Date) => void;
    setToDate: (date: Date) => void;
    setCount: (count: number) => void;
    setAnchorEl: (anchorEl: HTMLButtonElement | null) => void;
    setChangedValue?: (value: any) => void;
    reset?: any;
  };
}

const DateRangePicker = ({
  dateObj: {
    fromDate,
    toDate,
    count,
    anchorEl,
    setFromDate,
    setToDate,
    setCount,
    setAnchorEl,
    setChangedValue,
    reset,
  },
}: DateRangePickerProps) => {
  const [inputValue, setInputValue] = useState<any>(null);
  const [prevDates, setPrevDates] = useState([]);
  const [errorMsg, setErrorMsg] = useState(false);
  const open = Boolean(anchorEl);

  const onSelectDateRanges = ({ selection }) => {
    let { startDate, endDate } = selection;
    if (count % 2 !== 0) {
      setFromDate(startDate);
      setToDate(endDate);
      setAnchorEl(null);
      setPrevDates((prev) => [...prev.slice(-1), selection]);
    }
    startDate = moment(startDate);
    startDate = startDate.isValid() ? startDate.toDate() : undefined;

    endDate = moment(endDate);
    endDate = endDate.isValid() ? endDate.toDate() : undefined;

    let inputValue = '';
    if (startDate) inputValue += moment(startDate).format('YYYY/MM/DD');
    if (endDate) inputValue += ' - ' + moment(endDate).format('YYYY/MM/DD');
    setFromDate(startDate);
    setToDate(endDate);
    setInputValue(inputValue);
    setCount(count + 1);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const processInputValue = (value) => {
    let [fromDate, toDate] = value.split('-').map((elm) => elm.trim());

    fromDate = moment(fromDate, 'YYYY/MM/DD');
    fromDate = fromDate.isValid() ? fromDate.toDate() : undefined;

    toDate = moment(toDate, 'YYYY/MM/DD');
    toDate = toDate.isValid() ? toDate.toDate() : undefined;

    return { fromDate, toDate };
  };

  const onInputChange = (e) => {
    const inputValue = e.target.value;
    setInputValue(inputValue);
    setChangedValue(inputValue);
    const { fromDate, toDate } = processInputValue(inputValue);
    if (fromDate && toDate) {
      if (fromDate <= toDate) {
        setFromDate(fromDate);
        setToDate(toDate);
        setErrorMsg(false);
      } else {
        setErrorMsg(true);
      }
    } else {
      setErrorMsg(true);
    }
  };

  const handleClose = () => {
    setCount(0);
    setAnchorEl(null);
  };

  const setInitialDate = () => {
    let inputValue = '';
    if (fromDate) inputValue += moment(fromDate).format('YYYY/MM/DD');
    if (toDate) inputValue += ' - ' + moment(toDate).format('YYYY/MM/DD');
    setInputValue(inputValue);
  };

  useEffect(() => {
    setCount(0);
    setAnchorEl(null);
    setInitialDate();
  }, [reset]);

  useEffect(() => {
    setInitialDate();
  }, []);

  const changePrevDates = (date) => {
    const { startDate, endDate } = date;
    setFromDate(startDate);
    setToDate(endDate);
    let inputValue = '';
    if (startDate) inputValue += moment(startDate).format('YYYY/MM/DD');
    if (endDate) inputValue += ' - ' + moment(endDate).format('YYYY/MM/DD');
    setInputValue(inputValue);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (prevDates && prevDates.length === 0) {
      document.documentElement.style.setProperty('--display-prop', 'none');
    } else {
      document.documentElement.style.setProperty('--display-prop', 'block');
    }
  }, [prevDates]);

  return (
    <>
      <TextField
        size="small"
        fullWidth={true}
        label="Filter by Date"
        value={inputValue}
        onChange={onInputChange}
        error={errorMsg}
        style={{
          width: '230px',
          fontSize: '0.4rem !important',
        }}
        InputLabelProps={{
          style: {
            fontFamily: 'inherit',
            fontSize: '16px',
            letterSpacing: '-0.83px',
          },
          shrink: Boolean(inputValue),
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClick} style={{ padding: '0' }}>
                <DateRangeIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Popover
        id={'1'}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          top: '10px !important',
        }}
      >
        <DateRange
          locale={enUS}
          onChange={onSelectDateRanges}
          moveRangeOnFirstSelection={false}
          ranges={[
            {
              startDate: fromDate || new Date(),
              endDate: toDate || new Date(),
              key: 'selection',
            },
          ]}
          // this scroll won't work locally in react-18 with StrictMode -
          // https://github.com/hypeserver/react-date-range/issues/577
          scroll={{ enabled: process.env.REACT_APP_STAGE !== 'local' }}
        />
        <div className="chip-container">
          <Box>
            {prevDates.length > 0 && (
              <Typography
                variant="h6"
                fontWeight={900}
                sx={{
                  fontSize: '0.6rem !important',
                  color: theme.palette.grey[500],
                  marginLeft: '7px',
                  fontFamily: 'Sintony, sans-serif',
                  letterSpacing: '0.1rem',
                }}
              >
                Recently selected
              </Typography>
            )}
            <Stack direction="row" spacing={1.5} alignItems="center" marginLeft="8px">
              {prevDates.map((date, index) => {
                return (
                  <Chip
                    key={index}
                    variant="filled"
                    sx={{
                      backgroundColor: theme.palette.grey[50],
                      margin: '5px 0',
                      fontSize: '0.65rem !important',
                    }}
                    size="medium"
                    onClick={() => changePrevDates(date)}
                    label={`${moment(date.startDate).format('YYYY/MM/DD')} - ${moment(
                      date.endDate
                    ).format('YYYY/MM/DD')}`}
                  />
                );
              })}
            </Stack>
          </Box>
        </div>
      </Popover>
    </>
  );
};

export default DateRangePicker;
