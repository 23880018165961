import { useMutation } from '@tanstack/react-query';

import { Typography } from '@mui/material';

import { deleteUser } from 'services/userService';

import { useToaster } from 'contexts/ToasterContext';

import { PopUpButtonProps, User } from 'types';

import { PopUp } from '.';

interface RemoveMemberPopUpProps {
  isRemovePopUpOpen: boolean;
  close: () => void | Promise<void>;
  member: User;
  triggerRefetch: () => void;
}

export function RemoveMemberPopUp({
  isRemovePopUpOpen,
  close,
  member,
  triggerRefetch,
}: RemoveMemberPopUpProps): JSX.Element {
  const { firstName, lastName } = member;
  const name = `${firstName} ${lastName}`;

  const { triggerToast } = useToaster();

  const { mutate: deleteCurrentUser, isLoading: isDeletingUser } = useMutation(
    (userToDelete: User) => deleteUser(userToDelete),
    {
      onSuccess: () => {
        triggerRefetch();
        triggerToast({
          message: `${name}'s access has been removed`,
          type: 'success',
        });
        close();
      },
      onError: () => {
        triggerToast({
          message: `Error while trying to remove user`,
          type: 'error',
        });
        close();
      },
    }
  );

  const removeMember = () => {
    deleteCurrentUser(member);
  };

  const buttons: PopUpButtonProps[] = [
    { name: 'CANCEL', handler: close, variant: 'outlined' },
    { name: 'REMOVE', handler: removeMember, variant: 'contained', isLoading: isDeletingUser },
  ];

  return (
    <PopUp
      isPopUpOpen={isRemovePopUpOpen}
      buttons={buttons}
      close={close}
      description={
        <Typography sx={{ fontWeight: '600' }}>
          Are you sure you want to
          <Typography
            component="span"
            variant="body1"
            sx={{
              color: 'primary.main',
              fontWeight: '600',
              mx: '5px',
            }}
          >
            remove
          </Typography>
          {name} from the application?
        </Typography>
      }
    ></PopUp>
  );
}
