import { del, get, post, put } from 'aws-amplify/api';

import environment from 'config';
import { User } from 'types';

export const getAllUsers = async (): Promise<User[]> => {
  const { body } = await get({
    apiName: environment.API.REST.NAME,
    path: 'user',
    options: {},
  }).response;
  return (await body.json()) as User[];
};

export const postUser = async (user: User): Promise<void> => {
  await post({
    apiName: environment.API.REST.NAME,
    path: 'user',
    options: { body: user },
  }).response;
};

export const putUser = async (user: User): Promise<void> => {
  await put({
    apiName: environment.API.REST.NAME,
    path: `user/${user.email}`,
    options: { body: user },
  }).response;
};

export const deleteUser = async (user: User): Promise<void> => {
  await del({
    apiName: environment.API.REST.NAME,
    path: `user/${user.email}`,
    options: {},
  }).response;
};
