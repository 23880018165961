import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { TourProvider } from '@reactour/tour';
import { useQuery } from '@tanstack/react-query';
import { addDays, format, subDays } from 'date-fns';
import _ from 'lodash';
import debounce from 'lodash.debounce';
import { calenderTheme } from 'themes/calenderTheme';

import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography,
} from '@mui/material';
import { Container } from '@mui/system';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { Edit, HelpOutline, Publish } from '@mui/icons-material';

import { ActiveViewHeader } from 'components/Common/ActiveViewHeader';
import { Center } from 'components/Common/Centered';
import { MasterStreamControlButton } from 'components/ControlCenter/MasterStreamControlButton';
import { UserTourContent } from 'components/ControlCenter/UserTourContent';
import { EventDetail } from 'components/Event/EventDetail';
import { EventStatus } from 'components/Event/EventStatus';
import { ControlCenterHelpPopUp } from 'components/PopUp/ControlCenterHelpPopUp';
import { EventPopUp } from 'components/PopUp/EventPopUp';
import PublishPopUp from 'components/PopUp/PublishPopUp';

import * as EventService from 'services/eventService';
import { getGroupById } from 'services/groupService';

import { ActiveMenuContext } from 'contexts/ActiveMenuContext';

import { DATE_FORMAT } from 'constants/CommonConstants';
import { FeedNodeStatusType } from 'constants/DeviceStates';
import { ROUTE_KEY, ROUTE_PATH } from 'constants/RouteConstants';
import { EventStreamStatus } from 'constants/StreamConstants';
import { ControlCenterTour, UserTourClassSelector } from 'constants/UserTourConstants';

import { useActionAccess } from 'hooks/useActionAccess';
import { useEventUpdated } from 'hooks/useEventUpdated';
import { useIsMount } from 'hooks/useMount';
import { useStreamUpdated } from 'hooks/useStreamUpdated';

import environment from 'config';
import {
  APIEvent,
  EventStream,
  Output,
  OutputType,
  PopUpButtonProps,
  SingleAPIEvent,
  SourceBase,
} from 'types';

import { FeedGraph } from './FeedGraph';
import { UserRole } from '../constants/AuthConstants';
import { CurrentUserInfoContext } from '../contexts/CurrentUserInfoContext';
import { ToasterContext } from '../contexts/ToasterContext';
import { WebSocketSessionContext } from '../contexts/WebSocketContext';
import { isEventStreaming } from '../utils/helpers';

const displayNameForStreamStatus = {
  [EventStreamStatus.CREATED]: 'CREATE stream',
  [EventStreamStatus.DESTROYED]: 'DESTROY stream',
  [EventStreamStatus.STARTED]: 'START stream',
  [EventStreamStatus.STOPPED]: 'STOP stream',
  [EventStreamStatus.SOURCE_SELECTED]: 'Select Source',
};

const customInput = (props) => {
  return (
    <Tooltip placement="bottom" title={DATE_FORMAT.toLocaleLowerCase()}>
      <TextField {...props} size="small" />
    </Tooltip>
  );
};

const ControlCenter = (): JSX.Element => {
  const [selectedEvent, setSelectedEvent] = useState<SingleAPIEvent>(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedEventId, setSelectedEventId] = useState('');
  const { setActiveMenu } = useContext(ActiveMenuContext);
  const { canEditEvent } = useActionAccess();
  const [isLoading, setIsLoading] = useState(false);
  const isMount = useIsMount();
  const [editPopupState, setEditPopupState] = useState(false);
  const [helpPopupState, setHelpPopupState] = useState(false);
  const [hasFirstEventMounted, setHasFirstEventMounted] = useState(false);
  const { userInfo } = useContext(CurrentUserInfoContext);
  const defaultGroup = userInfo.role === UserRole.APPLICATION_ADMIN ? null : userInfo.group;
  const { subscribeToEvent, unsubscribeToEvent } = useContext(WebSocketSessionContext);
  const { triggerToast } = useContext(ToasterContext);
  const [publishPopupState, setPublishPopupState] = useState(false);

  const history = useHistory();

  const { eventId: eventIdFromParams } = useParams();

  useEffect(() => {
    setSelectedEventId(eventIdFromParams || '');
    if (!eventIdFromParams) {
      fetchEventList();
    }
  }, [eventIdFromParams]);

  useEffect(() => {
    setActiveMenu(ROUTE_KEY.CONTROL_CENTER);
  }, []);

  const { isFetching: isEventFetching, status: eventRequestStatus } = useQuery(
    ['event', selectedEventId],
    () => EventService.getEvent(selectedEventId),
    {
      enabled: Boolean(selectedEventId),
      onSuccess: (event) => {
        if (!hasFirstEventMounted) {
          setSelectedDate(new Date(event.scheduledTime));
        }
        setHasFirstEventMounted(true);
      },
      onSettled: (data, err) => {
        if (err) {
          setSelectedEvent(null);
        } else {
          // data.stream = {
          //   ...data.stream,
          //   status: EventStreamStatus.STOPPED,
          //   selectedSourceId: 'GfWv7k3B4XFExX4MSsXm0',
          // };
          setSelectedEvent(data);
        }
      },
    }
  );

  const {
    data: eventList = [],
    refetch: fetchEventList,
    isFetching: isEventListFetching,
    isError: isEventListError,
  } = useQuery(
    ['eventList'],
    async () => {
      const events = await EventService.listEvents({
        startDate: format(subDays(selectedDate, 1), 'yyyy-MM-dd'),
        endDate: format(addDays(selectedDate, 1), 'yyyy-MM-dd'),
        ...(defaultGroup ? { group: defaultGroup } : {}),
      });
      const filteredEvent = events.filter(
        (event) =>
          format(new Date(event.scheduledTime), 'MM/dd/yyyy') === format(selectedDate, 'MM/dd/yyyy')
      );
      return filteredEvent;
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: (events) => {
        if (events.length === 0) {
          triggerToast({
            type: 'error',
            message: 'No events available!',
          });
        } else if (!selectedEventId) {
          setSelectedEventId(events?.[0]?.id);
        }
      },
    }
  );

  useEffect(() => {
    const eventId = selectedEvent?.id;
    if (eventId) {
      history.replace({ pathname: `${ROUTE_PATH[ROUTE_KEY.CONTROL_CENTER]}/${eventId}` });
      // handle websocket subscriptions
      subscribeToEvent(eventId);
    }
    return () => {
      eventId && unsubscribeToEvent(eventId);
    };
  }, [selectedEvent?.id]);

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    if (!isMount) {
      fetchEventList();
    }
  }, [selectedDate]);

  const handlePublish = () => {
    const videoId = Object.keys(eventGroup.videoIds).find(
      (key) => _.toLower(key) === _.toLower(selectedValue)
    );
    if (!videoId) {
      triggerToast({ type: 'error', message: 'Publishing Failed!' });
      return;
    }

    setIsLoading(true);
    const url = `https://hls.${environment.baseUrl}/${selectedEvent.group}/${
      selectedEvent.season || '20232024'
    }/${selectedEvent.id}/live.m3u8`;
    EventService.publishBrightcove(selectedEvent.id, url, eventGroup.videoIds[videoId])
      .then(() => {
        triggerToast({ type: 'success', message: 'Published Successfully!' });
        setIsLoading(false);
      })
      .catch(() => {
        triggerToast({ type: 'error', message: 'Publishing Failed!' });
        setIsLoading(false);
      });
  };

  const { data: eventGroup } = useQuery(
    ['eventGroup', selectedEvent?.group],
    () => getGroupById(selectedEvent?.group),
    {
      enabled: Boolean(selectedEvent?.group),
    }
  );

  const publishButtonAttr: PopUpButtonProps[] = [
    {
      name: 'CANCEL',
      handler: () => {
        setPublishPopupState(false);
      },
      variant: 'outlined',
    },
    {
      name: 'Confirm',
      handler: handlePublish,
      variant: 'contained',
      isLoading: isLoading,
    },
  ];
  const [selectedValue, setSelectedValue] = useState<any>();

  const debounceWebsocketEventUpdates = useCallback(
    debounce((sources: SourceBase[], outputs: Output[]) => {
      setSelectedEvent((event) => {
        const commonChannel = outputs.find((o) => o.type === OutputType.COMMONCHANNEL);
        const updatedOutputs = outputs.map((o) => {
          if (o.type === OutputType.HLS || o.type === OutputType.RTMP) {
            o.status = commonChannel
              ? commonChannel.status
              : {
                  type: FeedNodeStatusType.NEGATIVE,
                  value: 'NOT PRESENT',
                };
          }
          return o;
        });
        return {
          ...event,
          sources,
          outputs: updatedOutputs,
        };
      });
    }, 400),
    []
  );

  const handleWebsocketStreamUpdates = useCallback((stream: EventStream) => {
    setSelectedEvent((event) => {
      return {
        ...event,
        stream,
      };
    });
  }, []);

  useEventUpdated(eventIdFromParams, debounceWebsocketEventUpdates);
  useStreamUpdated(eventIdFromParams, handleWebsocketStreamUpdates);

  const isPublishEnabled = useMemo(
    () => selectedEvent?.outputs?.find((o) => o.type === OutputType.HLS),
    [selectedEvent?.outputs]
  );
  return (
    <TourProvider
      steps={ControlCenterTour}
      ContentComponent={UserTourContent}
      styles={{
        popover: (base) => ({
          ...base,
          padding: 0,
          background: 'transparent',
          borderRadius: '6px',
        }),
      }}
    >
      <Container
        maxWidth={false}
        sx={{ py: '25px' }}
        disableGutters
        className={UserTourClassSelector.WELCOME}
      >
        <ActiveViewHeader title="Control Center">
          <Stack
            spacing={2}
            mt={{ xs: 4, sm: 1 }}
            direction={{ sm: 'row', xs: 'column' }}
            flexWrap="wrap"
            width={{ xs: '100%', md: 'auto' }}
          >
            <ThemeProvider theme={calenderTheme}>
              <DesktopDatePicker
                label="Filter by Date"
                format={DATE_FORMAT}
                value={selectedDate}
                onChange={handleDateChange}
                closeOnSelect={true}
                slots={{
                  textField: customInput,
                }}
              />
            </ThemeProvider>
            <Autocomplete
              disablePortal
              disabled={isEventListFetching}
              value={eventList.find((e) => e.id === selectedEventId) || null}
              size="small"
              options={eventList}
              onChange={(_, value: APIEvent) => {
                setSelectedEventId(value?.id || '');
              }}
              getOptionLabel={(value: APIEvent) => value.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Filter by Event Name"
                  error={isEventListError}
                  disabled={isEventFetching}
                  helperText={isEventListError && 'Unable to fetch events'}
                />
              )}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
            />
            <IconButton
              size="small"
              style={{
                marginLeft: '8px',
                color: 'white',
                background: '#1083DA',
              }}
              onClick={() => setHelpPopupState(true)}
            >
              <HelpOutline />
            </IconButton>
          </Stack>
        </ActiveViewHeader>
        {isEventFetching ? (
          <Center minHeight="50vh">
            <CircularProgress />
          </Center>
        ) : selectedEvent ? (
          <>
            <Grid container alignItems={'center'}>
              <Grid
                item
                sm={12}
                md={7}
                lg={8}
                display={'flex'}
                alignItems={'center'}
                columnGap={'10px'}
              >
                {/* @ts-ignore */}
                <EventDetail isInline event={selectedEvent} />
                <Box minWidth={'fit-content'}>
                  {/* @ts-ignore */}
                  <EventStatus event={selectedEvent} />
                </Box>
              </Grid>
              <Grid item sm={12} md={5} lg={4}>
                <Stack
                  direction="row"
                  spacing={{ xs: 3, md: 2 }}
                  rowGap="10px"
                  alignItems="center"
                  sx={{
                    justifyContent: {
                      xs: 'center',
                      md: 'flex-end',
                    },
                    marginTop: { xs: 2, md: 0 },
                  }}
                >
                  <Tooltip title={!isPublishEnabled ? 'No HLS Output' : ''}>
                    <span>
                      <Button //disabled = true
                        className={UserTourClassSelector.PUBLISH} // enabled false isloading false
                        disabled={isLoading || !isPublishEnabled ? true : false}
                        variant="contained"
                        size="small"
                        startIcon={isLoading ? <CircularProgress size={15} /> : <Publish />}
                        onClick={() => setPublishPopupState(true)}
                      >
                        Update Brightcove
                      </Button>
                    </span>
                  </Tooltip>
                  <MasterStreamControlButton
                    event={selectedEvent}
                    className={UserTourClassSelector.CREATE_STREAM}
                  />
                  {canEditEvent && (
                    <Tooltip
                      title={
                        !isEventStreaming(selectedEvent)
                          ? 'Event is not editable while streaming'
                          : ''
                      }
                    >
                      <div>
                        <IconButton
                          onClick={() => setEditPopupState(true)}
                          color="primary"
                          size="small"
                          disabled={!isEventStreaming(selectedEvent)}
                        >
                          <Edit />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )}
                </Stack>
              </Grid>
            </Grid>
            {selectedEvent && (
              <>
                <FeedGraph event={selectedEvent} />
              </>
            )}
          </>
        ) : (
          !isEventListFetching && (
            <Center width="100%" pt={10}>
              <>
                {selectedEvent}
                <Typography variant="body1" color="grey.500">
                  {eventRequestStatus === 'error'
                    ? '404! No event found'
                    : `No events found on ${selectedDate.toDateString()}`}
                </Typography>
              </>
            </Center>
          )
        )}
      </Container>
      <ControlCenterHelpPopUp
        eventFound={selectedEvent ? true : false}
        isControlCenterHelpPopUpOpen={helpPopupState}
        close={() => setHelpPopupState(false)}
      />
      {eventGroup && (
        <PublishPopUp
          isPublishPopUpOpen={publishPopupState}
          buttons={publishButtonAttr}
          eventGroup={eventGroup}
          selectedEvent={selectedEvent}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          close={() => setPublishPopupState(false)}
        />
      )}

      {selectedEvent && (
        <>
          <EventPopUp
            isEditMode
            eventToEdit={selectedEvent.id}
            isPopUpOpen={editPopupState}
            close={() => setEditPopupState(false)}
          />
        </>
      )}
    </TourProvider>
  );
};

export default ControlCenter;
