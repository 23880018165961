import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiError } from 'aws-amplify/api';
import { getAPIErrorMessage } from '../../utils/helpers';

import { Typography } from '@mui/material';

import * as EventService from 'services/eventService';

import { useToaster } from 'contexts/ToasterContext';

import { APIEvent, ErrorResponse, PopUpButtonProps } from 'types';

import { PopUp } from '.';

interface DeleteEventPopUpProps {
  isDeleteEventPopUpOpen: boolean;
  event: APIEvent;
  close: () => void | Promise<void>;
}

export function DeleteEventPopUp({
  isDeleteEventPopUpOpen,
  event,
  close,
}: DeleteEventPopUpProps): JSX.Element {
  const queryClient = useQueryClient();
  const { mutate: deleteEvent, isLoading } = useMutation(EventService.deleteEvent, {
    onError(error: ApiError) {
      const defaultMessage = 'Failed to delete event!';
      const errMessage = getAPIErrorMessage(error) || defaultMessage;
      const statusCode = error?.response?.statusCode || 500;
      triggerToast({
        message: statusCode === 500 ? defaultMessage : errMessage,
        type: 'error',
      });
      close();
    },
    onSuccess() {
      queryClient.invalidateQueries(['events']);
      triggerToast({ type: 'success', message: 'Event deleted!' });
      close();
    },
  });
  const { triggerToast } = useToaster();

  const buttons: PopUpButtonProps[] = [
    { name: 'CANCEL', handler: close, variant: 'outlined' },
    {
      name: 'DELETE',
      isLoading,
      handler: () => {
        deleteEvent({ eventId: event.id });
      },
      variant: 'contained',
    },
  ];

  return (
    <PopUp
      disableCTA={isLoading}
      isPopUpOpen={isDeleteEventPopUpOpen}
      buttons={buttons}
      close={close}
      description={
        <Typography sx={{ fontWeight: '600' }}>
          Are you sure you want to
          <Typography
            component="span"
            variant="body1"
            sx={{
              color: 'primary.main',
              fontWeight: '600',
              mx: '5px',
            }}
          >
            delete
          </Typography>
          the event ?
        </Typography>
      }
    ></PopUp>
  );
}
