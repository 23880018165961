import { get } from 'aws-amplify/api';

import environment from 'config';
import { AWSResource, OutdatedDevicesResponse } from 'types';

export const getSourceResourcesByID = async (id: string): Promise<AWSResource[]> => {
  const { body } = await get({
    apiName: environment.API.REST.NAME,
    path: `source/${id}/resources`,
    options: {},
  }).response;
  return (await body.json()) as AWSResource[];
};

export const getOutputResourcesByID = async (id: string): Promise<AWSResource[]> => {
  const { body } = await get({
    apiName: environment.API.REST.NAME,
    path: `output/${id}/resources`,
    options: {},
  }).response;
  return (await body.json()) as AWSResource[];
};

export const getBroadcasterIp = async (
  id: string
): Promise<{
  broadcasterIp: string;
  id: number;
  name: string;
}> => {
  const { body } = await get({
    apiName: environment.API.REST.NAME,
    path: `event/${id}/broadcaster`,
    options: {},
  }).response;
  return (await body.json()) as {
    broadcasterIp: string;
    id: number;
    name: string;
  };
};

export const getOutdatedMedialiveDevices = async (): Promise<OutdatedDevicesResponse[]> => {
  const { body } = await get({
    apiName: environment.API.REST.NAME,
    path: `awsResource/outdated-devices`,
    options: {},
  }).response;
  return (await body.json()) as unknown as OutdatedDevicesResponse[];
};
