import { useRef, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiError } from 'aws-amplify/api';

import { Box, IconButton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { DeleteRounded, MovieRounded } from '@mui/icons-material';

import { Center } from 'components/Common/Centered';
import { PopUp } from 'components/PopUp';

import * as SourceService from 'services/sourceService';

import { useToaster } from 'contexts/ToasterContext';

import { ErrorResponse, PopUpButtonProps, Slate } from 'types';
import HlsVideoPlayer from '../Common/HlsVideoPlayer';
import { getAPIErrorMessage } from '../../utils/helpers';

interface ISlateCard {
  slate: Slate;
  showDelete: boolean;
}

export const SlateCard = ({ slate, showDelete }: ISlateCard) => {
  const [isDeleteSlatePopUpOpen, setIsDeleteSlatePopUpOpen] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const playerRef = useRef<HTMLVideoElement>(null);
  const queryClient = useQueryClient();
  const { triggerToast } = useToaster();
  const { mutate: deleteSlate, isLoading: isDeleting } = useMutation(SourceService.deleteSlate, {
    onError(error: ApiError) {
      const defaultMessage = 'Failed to delete slate!';
      const errMessage = getAPIErrorMessage(error) || defaultMessage;
      const statusCode = error?.response?.statusCode || 500;
      triggerToast({
        message: statusCode === 500 ? defaultMessage : errMessage,
        type: 'error',
      });
      setIsDeleteSlatePopUpOpen(false);
    },
    onSuccess() {
      queryClient.invalidateQueries(['slates']);
      triggerToast({ type: 'success', message: 'Slate deleted!' });
      setIsDeleteSlatePopUpOpen(false);
    },
  });

  const buttons: PopUpButtonProps[] = [
    { name: 'CANCEL', handler: () => setIsDeleteSlatePopUpOpen(false), variant: 'outlined' },
    {
      name: 'DELETE',
      handler: () => {
        deleteSlate(slate.id);
      },
      isLoading: isDeleting,
      variant: 'contained',
    },
  ];

  return (
    <Stack
      p={2}
      borderRadius={1}
      width="100%"
      height="300px"
      border="1px solid"
      borderColor="highlight.secondary"
      overflow="hidden"
      direction="column"
      alignItems="flex-start"
      sx={{
        '&:hover': {
          borderColor: 'grey.300',
        },
      }}
    >
      <Stack direction="row" justifyContent="space-between" mb={1} width="100%">
        <Box>
          <Typography>
            <b>{slate.name}</b>
          </Typography>
          <Typography variant="body2" color="grey.600">
            {slate.group}
          </Typography>
        </Box>
        {showDelete && (
          <IconButton size="small" color="error" onClick={() => setIsDeleteSlatePopUpOpen(true)}>
            <DeleteRounded />
          </IconButton>
        )}
      </Stack>
      <Box
        width="100%"
        maxHeight="calc(100% - 52px)"
        borderRadius={1}
        flexGrow={1}
        position="relative"
      >
        <HlsVideoPlayer
          customStyles={{ borderRadius: '5px' }}
          src={`${slate.url}.m3u8`}
          videoRef={playerRef}
          controls
          onLoadedMetadata={() => {
            setHasLoaded(true);
          }}
        />
        {!hasLoaded && (
          <Center
            width="100%"
            height="100%"
            borderRadius={1}
            bgcolor="grey.100"
            color="grey.600"
            position="absolute"
            top="0"
            textAlign="center"
          >
            <MovieRounded fontSize="large" sx={{ mb: 1 }} />
            <Typography variant="body2">Loading...</Typography>
          </Center>
        )}
      </Box>
      <PopUp
        isPopUpOpen={isDeleteSlatePopUpOpen}
        buttons={buttons}
        close={() => setIsDeleteSlatePopUpOpen(false)}
        description={
          <Typography sx={{ fontWeight: '600' }}>
            Are you sure you want to
            <Typography
              component="span"
              variant="body1"
              sx={{
                color: 'primary.main',
                fontWeight: '600',
                mx: '5px',
              }}
            >
              delete
            </Typography>
            the slate ?
          </Typography>
        }
      ></PopUp>
    </Stack>
  );
};
