import { useState } from 'react';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { ApiError } from 'aws-amplify/api';

import * as EventService from 'services/eventService';

import { useToaster } from 'contexts/ToasterContext';

import { getAPIErrorMessage } from '../utils/helpers';

export interface StreamApiReturnType {
  eventOperation: UseMutateFunction<
    void,
    unknown,
    {
      eventId: string;
      operation: string;
    },
    unknown
  >;
  switchSource: UseMutateFunction<
    void,
    unknown,
    {
      eventId: string;
      sourceId: string;
    },
    unknown
  >;
}

export const useStreamApi = (state?: any): any => {
  const { triggerToast } = useToaster();
  const [isLoading, setIsLoading] = useState(false);

  const onError = (defaultMessage: string) => (error: ApiError) => {
    const errMessage = getAPIErrorMessage(error) || defaultMessage;
    const statusCode = error?.response?.statusCode || 500;
    triggerToast({ type: 'error', message: statusCode === 500 ? defaultMessage : errMessage });
  };

  // const { mutate: createStream } = useMutation(EventService.createEventStream, {
  //   onError: onError('Unable to create stream'),
  // });
  // const { mutate: destroyStream } = useMutation(EventService.destroyEventStream, {
  //   onError: onError('Unable to destroy stream'),
  // });
  // const { mutate: resetStream } = useMutation(EventService.resetEventStream, {
  //   onError: onError('Unable to reset stream'),
  // });
  // const { mutate: startStream } = useMutation(EventService.startEventStream, {
  //   onError: onError('Unable to start stream'),
  // });
  // const { mutate: stopStream } = useMutation(EventService.stopEventStream, {
  //   onError: onError('Unable to stop stream'),
  // });

  const { mutate: switchSource } = useMutation(EventService.switchEventStreamSource, {
    onError: (error: ApiError) => onError('Unable to switch input for the stream')(error),
  });

  // TODO give more specific error
  const { mutate: eventOperation } = useMutation(EventService.eventStreamOperation, {
    onSuccess: () => {
      if (state) {
        state((prevState) => !prevState);
      }
    },
    onError: (error: ApiError) => {
      onError(`Unable to perform stream operation!!`)(error);
      if (state) {
        state((prevState) => !prevState);
      }
    },
  });

  return {
    isLoading,
    eventOperation,
    switchSource,
  };
};
