import { del, get, post } from 'aws-amplify/api';
import axios from 'axios';
import _ from 'lodash';

import environment from 'config';
import { AWSResource, presignedUrlRequest, Slate, SourceBase } from 'types';

export const listAWSResources = async (
  type?: string,
  group?: string
): Promise<AWSResource<any>[]> => {
  const queryParams = { type, group };
  const { body } = await get({
    apiName: environment.API.REST.NAME,
    path: 'awsResource',
    options: {
      queryParams: _.omitBy(queryParams, _.isUndefined),
    },
  }).response;
  return (await body.json()) as AWSResource<any>[];
};

export const listSlates = async (group?: string): Promise<Slate[]> => {
  const { body } = await get({
    apiName: environment.API.REST.NAME,
    path: 'slate',
    options: {
      queryParams: group ? { group } : {},
    },
  }).response;
  return (await body.json()) as Slate[];
};

export const getAllSources = async (): Promise<SourceBase[]> => {
  const { body } = await get({
    apiName: environment.API.REST.NAME,
    path: 'source',
    options: {},
  }).response;
  return (await body.json()) as SourceBase[];
};

export const getPresignedURL = async (body: presignedUrlRequest[]): Promise<any> => {
  const data = {
    slates: body,
  };
  const { body: responseBody } = await post({
    apiName: environment.API.REST.NAME,
    path: 'slate/get-presigned-url',
    options: { body: data },
  }).response;
  return await responseBody.json();
};

export const uploadFile = async (url: string, file: File) => {
  const buff = await file.arrayBuffer();
  return axios.put(url, buff, { headers: { 'Content-Type': file.type } });
};

export const deleteSlate = async (slateId: string): Promise<void> => {
  await del({
    apiName: environment.API.REST.NAME,
    path: `slate/${slateId}`,
    options: {},
  }).response;
};

export const getSlate = async (slateId: any): Promise<any> => {
  const { body } = await get({
    apiName: environment.API.REST.NAME,
    path: `slate/${slateId}`,
    options: {},
  }).response;
  return await body.json();
};
